<template>
  <div class="ApplyService">
    <van-nav-bar
      title="开店流程"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-cell :border="false">
      <template #title>
        <div>
          <van-image
            style="width: 100%; height: auto;"
            lazy-load
            src="https://img.midbest.cn/uploads/image/order/kongjia/2020/11/03/dedfedb8ed1254fc985663043f09b4e2.png"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
        <div>
          <div class="title_text">申请前请添加审核客服微信。</div>
          <van-image
            style="width: 50%; height: auto;"
            lazy-load
            src="https://img.midbest.cn/uploads/kefu/17.jpg"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
      </template>
    </van-cell>
    <div class="nex_setup">
      <van-button type="danger" @click="commition" block>下一步</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ApplyService",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "开店流程");
  },
  methods: {
    commition() {
      // 已经登陆直接进入申请页面
      this.$router.push({ name: "GonghuoApply2", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>
<style lang="less">
.ApplyService {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .van-cell {
    overflow: hidden;
    .van-cell__title {
      flex: 1;
      overflow: hidden;
    }
    .title_text {
      width: 100%;
      margin: 20px auto 5px;
      font-size: 14px;
    }
  }
  .nex_setup {
    padding: 20px;
  }
}
</style>